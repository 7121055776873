<template>
  <BaseAlert
    variant="warning"
    :dismissible="dismissible"
    :duration="duration"
    @dismissed="$emit('dismissed')"
  >
    <template #icon>
      <WarningIcon width="18" height="18" class="fill-inherit" />
    </template>
    <slot />
  </BaseAlert>
</template>
<script>
import WarningIcon from '~/assets/icons/warning.svg'
import BaseAlert from '~/components/alerts/BaseAlert'

export default {
  components: {
    WarningIcon,
    BaseAlert,
  },
  props: {
    dismissible: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  emits: ['dismissed'],
}
</script>
